import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { useAuth } from "../context/auth-context"
import SEO from "../components/seo"
import Press from "../components/press"
import Header from "../components/layout/header"
import ForWorkDropdown from "../components/homepage/for-work-dropdown"
import SimplifiedHomepageButtons from "../components/homepage/simplified-buttons"
import { isBrowser } from "../utils/browser"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../utils/link-resolver"
import { seoKeywords } from "../utils/helpers"
import parse from "html-react-parser"

function IndexPage() {
  const { isLoggedIn } = useAuth()

  const { allPrismicSimplifiedHomepage } = useStaticQuery(graphql`
    query SimplifiedHomepageQuery {
      allPrismicSimplifiedHomepage {
        edges {
          node {
            data {
              acquisition_text {
                html
              }
              show_acquisition
              seo_title {
                text
              }
              seo_description {
                text
                html
              }
              seo_keywords {
                text
                html
              }
              logo {
                alt
                url
              }
              lede_image {
                alt
                url
              }
              mobile_lede_image {
                alt
                url
              }
              header {
                html
                text
              }
              subheader {
                html
                text
              }
              dropdown_header {
                html
                text
              }
              after_loss_button_text {
                html
                text
              }
              preplanning_button_text {
                html
                text
              }
              press {
                document {
                  ... on PrismicPress {
                    data {
                      press {
                        source
                        logo {
                          url
                        }
                        link {
                          url
                        }
                        custom_classes
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const {
    seo_title,
    seo_description,
    seo_keywords,
    logo,
    header,
    subheader,
    lede_image,
    mobile_lede_image,
    dropdown_header,
    after_loss_button_text,
    preplanning_button_text,
    press,
    acquisition_text,
    show_acquisition,
  } = allPrismicSimplifiedHomepage.edges[0].node.data

  const TopNavigation = () => (
    <div className="flex flex-col">
      {show_acquisition && (
        <div className="flex flex-row w-full mx-auto my-auto py-3 justify-center md:mx-auto bg-white">
          <h2 className="text-primary pt-3 body-text ">
            {parse(acquisition_text?.html)}
          </h2>
        </div>
      )}
      <div className="flex flex-row w-full max-w-full mx-auto px-6 md:px-12 mb-4 pt-6 md:pt-8">
        <div className="w-1/2">
          <div className="md:w-1/5">
            <Link to="/">
              <img src={logo.url} width="150" alt={logo.alt} />
            </Link>
          </div>
        </div>
        <div className="w-1/2 flex flex-row-reverse">
          {isLoggedIn() ? (
            <Link
              className="navigation-text text-primary hover:text-grey-darker"
              to="/app/dashboard"
            >
              My Plans
            </Link>
          ) : (
            <div
              id="login-button"
              className="flex items-center align-middle justify-center"
            >
              <Link
                className="navigation-text text-primary hover:text-grey-darker mr-0 .leading-none"
                to="/login"
              >
                Log In
              </Link>
              <i className="material-icons text-primary hover:text-grey-darker inline-block align-middle">
                chevron_right
              </i>
            </div>
          )}
        </div>
      </div>
    </div>
  )

  const MainBody = () => (
    <main className="h-auto px-6 md:px-12 max-w-full mx-auto md:h-64 flex flex-col md:flex-row flex-grow md:justify-center bg-secondary overflow-visible md:overflow-hidden">
      <div
        className={`w-full pt-8 md:pt-0 flex flex-col items-center justify-center md:pr-0 ${
          isBrowser() && window.screen.width > 1440 ? "" : "px-6 md:pl-16"
        }`}
      >
        <Header
          text_mobile={header.text}
          text={header.text}
          type="page"
          showOutOfApp
        />
        <h2 className="sub-headline-text">{subheader.text}</h2>

        <div className="hidden md:block w-full">
          <ForWorkDropdown header={dropdown_header.text} additionalClasses="" />
          <SimplifiedHomepageButtons
            firstButtonText={after_loss_button_text.text}
            secondButtonText={preplanning_button_text.text}
          />
        </div>
      </div>

      <div className="w-full flex h-auto">
        <img
          src={
            isBrowser() && window.screen.width > 767
              ? lede_image.url
              : mobile_lede_image.url
          }
          className="w-full md:object-cover"
          alt=""
        />
      </div>
    </main>
  )

  const Footer = () => (
    <footer className="bg-grey-lighter pt-6 pb-10 md:px-32">
      <div className="max-w-body mx-auto">
        <Press press={press.document.data.press} />
      </div>
    </footer>
  )

  return (
    <div className="min-h-screen h-screen flex flex-col bg-secondary">
      <SEO
        keywords={[seoKeywords(seo_keywords?.text)]}
        title={seo_title?.text}
        description={seo_description?.text}
        excludeSiteMetadataTitle
      />
      {/* <SEO
        keywords={[`end`, `life`, `funeral`, `planning`, `lantern`]}
        title="End of Life Planning | Funeral Planning Checklist | Lantern"
      /> */}
      <TopNavigation />

      <MainBody />

      <div className="block md:hidden">
        <SimplifiedHomepageButtons
          firstButtonText={after_loss_button_text.text}
          secondButtonText={preplanning_button_text.text}
          additionalClasses="bg-grey-light"
        />
        <ForWorkDropdown
          header={dropdown_header.text}
          additionalClasses="bg-grey-light"
        />
      </div>
      <Footer />
    </div>
  )
}

export default withPrismicPreview(IndexPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])

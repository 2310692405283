import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import Button from "../button"

function SimplifiedHomepageButtons({
  firstButtonText,
  secondButtonText,
  additionalClasses,
}) {
  return (
    <section className={additionalClasses}>
      <div className="flex flex-col md:flex-row px-6 md:px-0">
        <Button
          buttonText={firstButtonText}
          buttonType="btn out-of-app -primary"
          handleOnClick={(event) => {
            event.preventDefault()
            navigate("/managing-a-death")
          }}
          additionalClasses="md:ml-0 w-full md:w-1/2 p-2 text-sm md:self-start self-center mt-8 md:mt-0"
          buttonId="homepage-post-loss"
          eventName={{
            eventDomain: "Out of App",
            eventLocation: "Simplified Homepage",
            eventAction: "Click",
            eventActionName: "Go To Managing A Death",
          }}
        />
        <Button
          buttonText={secondButtonText}
          buttonType="btn out-of-app -primary"
          handleOnClick={(event) => {
            event.preventDefault()
            navigate("/planning-ahead")
          }}
          additionalClasses="w-full md:w-1/2 p-2 text-sm md:self-start self-center mt-3 md:mt-0 md:ml-4"
          buttonId="homepage-planning-ahead"
          eventName={{
            eventDomain: "Out of App",
            eventLocation: "Simplified Homepage",
            eventAction: "Click",
            eventActionName: "Go To Planning Ahead",
          }}
        />
      </div>
    </section>
  )
}

SimplifiedHomepageButtons.propTypes = {
  firstButtonText: PropTypes.string.isRequired,
  secondButtonText: PropTypes.string.isRequired,
  additionalClasses: PropTypes.string,
}

SimplifiedHomepageButtons.defaultProps = {
  additionalClasses: "",
}

export default SimplifiedHomepageButtons

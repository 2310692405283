import React from "react"
import PropTypes from "prop-types"

function Press({ press }) {
  return (
    <section className="flex flex-wrap -mx-2 overflow-hidden content-center items-center md:justify-between">
      {press.map((item, index) => (
        <div key={index} className="my-2 px-2 w-1/3 overflow-hidden md:w-auto">
          <a
            href={item.link.url}
            target="_blank"
            rel="noreferrer"
            className="flex justify-center object-contain h-20"
          >
            <img src={item.logo.url} className="w-20 object-contain" alt="" />
          </a>
        </div>
      ))}
    </section>
  )
}

Press.propTypes = {
  press: PropTypes.array.isRequired,
}

Press.defaultProps = {
  hideHeader: false,
}

export default Press

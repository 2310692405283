import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

function ForWorkDropdown({ header, additionalClasses }) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <section
      className={`pt-6 md:pt-0 pb-8 px-6 md:px-0 ${additionalClasses}`}
      onClick={() => setIsOpen(!isOpen)}
    >
      <span className="flex flex-row items-center justify-center md:justify-start text-primary hover:text-grey-darker">
        <i className="material-icons inline-block align-middle cursor-pointer">
          {isOpen ? "keyboard_arrow_up" : "chevron_right"}
        </i>
        <div className="font-semibold text-sm cursor-pointer md:text-base mx-0">
          {header}
        </div>
      </span>
      <div
        className={`flex flex-col body-text text-primary underline ml-16 md:ml-6 ${
          isOpen ? "block" : "hidden"
        }`}
      >
        <Link className="hover:text-grey-darker" to="/work">
          Lantern for Employers
        </Link>
        <Link className="hover:text-grey-darker" to="/business">
          Lantern for Your Clients
        </Link>
        <Link className="hover:text-grey-darker" to="/deathatwork">
          Workshops and Guides
        </Link>
      </div>
    </section>
  )
}

ForWorkDropdown.propTypes = {
  header: PropTypes.string.isRequired,
  additionalClasses: PropTypes.string,
  handleOnClick: PropTypes.func,
  isOpen: PropTypes.bool,
}

ForWorkDropdown.defaultProps = {
  additionalClasses: "",
  isOpen: false,
  handleOnClick: () => {},
}

export default ForWorkDropdown
